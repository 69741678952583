import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { AngularFireDatabase } from 'angularfire2/database';
import * as firebase from 'firebase/app';

import { zip, empty } from 'rxjs';
import { switchMap, debounceTime } from 'rxjs/operators';

import { OldData, User } from './shared/types';

@Component({
  selector: 'zult-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  title = 'zubaidi-reg';


  currentDB = 'union_journey_2019';
  myForm: FormGroup;
  oldData: OldData = { key: null, name: '', mobile: '', email: '', eid: '' };

  success = false;
  loading = false;

  mobileNumber$ = null;

  mobileNumber2 = '';
  mobileNumber2Code = '971';

  mobileNumberRegex = /^0?[1-9]\d{8,8}$/;
  mobileNumberRegexGroup = /0?(\d{9,9})/;

  constructor(private formBuilder: FormBuilder, private db: AngularFireDatabase) { }

  ngOnInit() {
    this.init();
  }

  init() {
    this.myForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      // middleName: ['', Validators.required],
      lastName: ['', Validators.required],
      mobileNumbers: this.formBuilder.array([]),
      email: ['', Validators.email],
      eid: ['', Validators.required]
    });
    this.addNumber();
    this.enableControls(false);
    this.handleMobileNumber();
  }

  reloadApp() {
    this.oldData = { key: null, name: '', mobile: '', email: '', eid: '' };
    this.success = false;
    this.loading = false;
    this.mobileNumber$ = null;
    this.mobileNumber2 = '';
    this.mobileNumber2Code = '971';
    this.init();
  }

  handleMobileNumber() {
    let numberInput = '';
    let number;

    this.mobileNumber$ = this.mobileNumbersForm.controls[0].valueChanges.pipe(
      debounceTime(50),
      switchMap(mobileNumber => {
        this.loading = true;
        this.enableControls(false);
        numberInput = mobileNumber.number as string;
        if ((numberInput.length === 9 || numberInput.length === 10) && this.mobileNumberRegex.test(numberInput)) {
          number = this.mobileNumberRegexGroup.exec(numberInput);
          let [mobileNumber0$, mobileNumber1$] = [null, null];
          mobileNumber0$ = this.db.list<any>(
            '/data',
            ref => ref.orderByChild('mobileNumbers/0').equalTo(`+${mobileNumber.countryCode} ${number[1]}`)
          ).snapshotChanges();
          mobileNumber1$ = this.db.list<any>(
            '/data',
            ref => ref.orderByChild('mobileNumbers/1').equalTo(`+${mobileNumber.countryCode} ${number[1]}`)
          ).snapshotChanges();
          return zip(mobileNumber0$, mobileNumber1$);
        }
        return empty();
      })
    );

    this.mobileNumber$
      .subscribe(result => {
        const [snapshot1, snapshot2] = result as [any, any];
        if (snapshot1.length || snapshot2.length) {
          let payload = null;
          let snapshotWon = null;
          if (snapshot1.length) {
            payload = this.getLatestPayload(snapshot1);
            snapshotWon = 0;
          } else if (snapshot2.length) {
            payload = this.getLatestPayload(snapshot2);
            snapshotWon = 1;
          }
          const data = payload.val();
          const secondMobile = (snapshotWon === 0) ? 1 : 0;
          let mobileNumber = [null, '971', ''];
          const codeAndMobileNumberRegex = /\+(\d+) (\d+)/;
          if (data.mobileNumbers[secondMobile]) {
            mobileNumber = codeAndMobileNumberRegex.exec(data.mobileNumbers[secondMobile]);
          }
          this.oldData = {
            key: payload.key || null,
            name: data.firstName || '',
            mobile: codeAndMobileNumberRegex.exec(data.mobileNumbers[snapshotWon])[2],
            email: data.email || '',
            eid: data.eid || ''
          };
          this.mobileNumber2 = mobileNumber[2];
          this.mobileNumber2Code = mobileNumber[1];
          this.enableControls(true, this.oldData);
          this.loading = false;
        } else {
          this.db.list<OldData>(
            '/union_journey',
            ref => ref.orderByChild('mobile').equalTo('971 ' + number[1])
          ).snapshotChanges()
            .subscribe(resultOld => {
              const snapshot = resultOld as any[];
              if (snapshot.length) {
                const payload = snapshot[0].payload || { key: null };
                this.oldData = payload.val() || { key: null, name: '', mobile: '', email: '', eid: '' };
                this.oldData.key = payload.key;
              } else {
                this.oldData = { key: null, name: '', mobile: '', email: '', eid: '' };
              }
              this.enableControls(true, this.oldData);
              this.loading = false;
            });
        }
      });

  }

  getLatestPayload(snapshot: [any]) {
    let payloadData = [];
    for (const snap of snapshot) {
      payloadData.push({ key: snap.key, val: () => snap.payload.val() });
    }
    payloadData = payloadData.sort((a, b) => b.val().timestamp - a.val().timestamp);
    return payloadData[0];
  }

  async submit() {
    const timestamp = firebase.database.ServerValue.TIMESTAMP;

    if (this.loading || this.myForm.invalid) {
      return;
    }
    this.loading = true;
    const formValues = this.myForm.value;

    formValues.oldKey = this.oldData.key;

    const mobileNumbers = [];
    for (const mobileNumber of formValues.mobileNumbers) {
      mobileNumbers.push(`+${mobileNumber.countryCode} ${this.mobileNumberRegexGroup.exec(mobileNumber.number)[1]}`);
    }
    formValues.mobileNumbers = mobileNumbers;
    formValues.timestamp = timestamp;

    try {
      await this.db.list(this.currentDB).push(formValues);
      await this.db.list('data').push(formValues);
      this.success = true;
    } catch (error) {
      console.error('Error:', error);
    }
    this.loading = false;
  }

  enableControls(enable: boolean, result?: OldData) {
    if (enable) {
      const name = result.name;
      const email = result.email;
      const eid = result.eid;
      this.myForm.controls.firstName.enable();
      this.myForm.controls.firstName.setValue(name);
      // this.myForm.controls.middleName.enable();
      // this.myForm.controls.middleName.setValue(`${name[1] || ''} ${name[2] || ''}`.trim());
      this.myForm.controls.lastName.enable();
      // this.myForm.controls.lastName.setValue(`${name[1] || ''} ${name[2] || ''}`.trim());
      this.myForm.controls.lastName.setValue('الزبيدي');
      this.myForm.controls.email.enable();
      this.myForm.controls.email.setValue(email);
      this.myForm.controls.eid.enable();
      this.myForm.controls.eid.setValue(eid);

    } else {
      this.myForm.controls.firstName.disable();
      // this.myForm.controls.middleName.disable();
      this.myForm.controls.lastName.disable();
      this.myForm.controls.email.disable();
      this.myForm.controls.eid.disable();
    }
  }

  get mobileNumbersForm() {
    return this.myForm.controls.mobileNumbers as FormArray;
  }

  addNumber(add: boolean = true) {
    if (!add) { return; }
    const mobileNumber = this.formBuilder
      .group({
        countryCode: [
          this.mobileNumber2Code,
          [Validators.required]
        ],
        number: [
          this.mobileNumber2,
          [
            Validators.required,
            Validators.minLength(9),
            Validators.pattern(this.mobileNumberRegex)
          ]]
      });
    this.mobileNumbersForm.push(mobileNumber);
  }

  removeNumber(index: number) {
    this.mobileNumber2Code = this.mobileNumbersForm.controls[index].value.countryCode;
    this.mobileNumber2 = this.mobileNumbersForm.controls[index].value.number;
    this.mobileNumbersForm.removeAt(index);
  }
}
